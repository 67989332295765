// import React from "react";
// import { Link } from "react-router-dom";
// import styles from "../../../styles/styles";
// import poster from "../../../Assests/images/Untitleddesign.png";
// const Hero = () => {
//   return (
//     <div
//       className={`relative min-h-[50vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.noramlFlex}`}
//       // className={`relative min-h-[30vh] sm:min-h-[30vh] md:min-h-[80vh] lg:min-h-[80vh] xl:min-h-[80vh] w-full bg-no-repeat ${styles.normalFlex}`}
//       style={{
//         // backgroundImage: "url({poster})",
//         backgroundImage: `url(${poster})`,
//         backgroundSize: "cover",
//         backgroundRepeat: "no-repeat",
//         backgroundPosition: "center",
//         width: "100%",
//         height: "100%",
//       }}
//     >
//       {/* <img src={poster} alt="" /> */}
//       <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
//         {/* <h1
//           className={`text-[35px] leading-[1.2] 800px:text-[60px] text-[#3d3a3a] font-[600] capitalize`}
//         >
//           Best Collection for <br /> For Your Dream Cars
//         </h1>
//         <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
//           Lorem ipsum dolor sit amet consectetur, adipisicing elit. Beatae,
//           assumenda? Quisquam itaque <br /> exercitationem labore vel, dolore
//           quidem asperiores, laudantium temporibus soluta optio consequatur{" "}
//           <br /> aliquam deserunt officia. Dolorum saepe nulla provident.
//         </p> */}
//         {/* <Link
//           to="https://api.whatsapp.com/send?phone=9318429857"
//           className="inline-block"
//           target="_blank"
//         >
//           <div className={`${styles.button} mt-5`}>

//             <span className="text-[#fff] font-[Poppins] text-[18px]">
//               Whats App
//             </span>
//           </div>
//         </Link> */}
//       </div>
//     </div>
//   );
// };

// export default Hero;

// kam chalne layak h
import React from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import poster from "../../../Assests/images/1.png";
import poster2 from "../../../Assests/images/2.png";

const Hero = () => {
  return (
    <div
      className={`relative min-h-[10vh] md:min-h-[45vh] w-full ${styles.normalFlex}`}
    >
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        autoPlay={true}
        showThumbs={false}
        showStatus={false}
        interval={5000} // Set the interval between slides in milliseconds
        transitionTime={200} // Set the transition time in milliseconds
        // className="absolute top-0 left-0 w-full h-full"
      >
        <div>
          <img src={poster} alt="meat" className="w-full h-full object-cover" />
        </div>
        <div>
          <img
            src={poster2}
            alt="masala"
            className="w-full h-full object-cover"
          />
        </div>
      </Carousel>

      {/* <div
        className={`${styles.section} w-[90%] text-center md:w-[60%] z-10 relative`}
      >
        <h1
          className={`text-[40px] leading-[1.1] md:text-[60px] text-white font-semibold capitalize`}
        >
          Meat Your Need! Don't <br />
          Forget the InstaMeat
        </h1>
        <p className="pt-5 text-[15px] font-[Poppins] font-[400] text-white">
          We source and sell the very best beef, lamb, and pork sourced with
          <br /> the greatest care from farmers.
        </p>
        <Link to="/products" className="inline-block">
          <div className={`${styles.button3} mt-5`}>
            <span className="font-[Poppins] text-[18px]">Explore More</span>
          </div>
        </Link>
      </div> */}
    </div>
  );
};

export default Hero;
